import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../hooks/useMediaQuery"

const WorkAto = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "24px 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Unlock the Full Value of Workato With Your Consultants
            </h2>
            <p
              className="text-center mx-auto px-sm-2"
              style={{ maxWidth: "825px" }}
            >
              As consultative partners, we go beyond technical
              execution—designing, implementing, and optimizing automations that
              drive measurable business impact.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Increase Time-to-Value
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/bbb1bb4f9dbc8f4728b8fb1e5deeff0de37e51ce-1280x853.jpg"
                      alt="Increase Time-to-Value"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Increase Time-to-Value</h3>
                    <p className="my-4 text-left-mobile">
                      Using our expertise, we design, implement, and refine
                      Workato solutions that achieve operational efficiency and
                      ROI in weeks.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Tailored Automations
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Tailored Automations</h3>
                    <p className="my-4 text-left-mobile">
                      We build customized automation strategies that align with
                      your goals, ensuring optimal system performance and
                      business relevance.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c4288938a0de0631b3e301087418d1be0776c2c9-1279x853.jpg"
                      alt="Tailored Automations"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mx-auto text-center"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Seamless Integrations
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f5d926c5cd7731ef9432990c0ffb87975f268dac-1280x855.jpg"
                      alt=" Seamless Integrations
"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Seamless Integrations</h3>
                    <p className="my-4 text-left-mobile">
                      Our specialized knowledge in connecting diverse systems
                      ensures smooth data flow and application interoperability
                      for increased productivity.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Proactive Support
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Proactive Support</h3>
                    <p className="my-4 text-left-mobile">
                      Get ongoing support, system performance monitoring, and
                      proactive suggestions that enhance workflows for your
                      evolving business needs.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/3d6825cfc4e44c99c5c606945e23f171b75ef715-1279x853.jpg"
                      alt="Proactive Support 
"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab5"
              />
              <label
                for="tab5"
                class="tabs__label mx-auto text-center"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Knowledge Transfer
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/8d8c744b42542b7e4089e04a492812a2c182c78f-1280x853.jpg"
                      alt="Knowledge Transfer"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Knowledge Transfer</h3>
                    <p className="my-4 text-left-mobile">
                      Empower your teams with our knowledge and best practices,
                      enabling your organization to maintain and expand
                      automation solutions.​
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Increase Time-to-Value</h3>
                    <p className="my-4 ">
                      Using our expertise, we design, implement, and refine
                      Workato solutions that achieve operational efficiency and
                      ROI in weeks.
                    </p>

                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/technology-consulting/"
                    >
                      Start Your Analysis
                      <span className="d-none">
                        This is a redirection to quandary-build
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    {/* <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/bbb1bb4f9dbc8f4728b8fb1e5deeff0de37e51ce-1280x853.jpg"
                      alt="Find and Fix Security Risks"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Tailored Automations</h3>
                    <p className="my-4 ">
                      We build customized automation strategies that align with
                      your goals, ensuring optimal system performance and
                      business relevance.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c4288938a0de0631b3e301087418d1be0776c2c9-1279x853.jpg"
                      alt="Tailored Automations"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Seamless Integrations</h3>
                    <p className="my-4 ">
                      Our specialized knowledge in connecting diverse systems
                      ensures smooth data flow and application interoperability
                      for increased productivity.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/citizen-development-support/"
                    >
                      Find Your AI Gaps
                      <span className="d-none">
                        This is a redirection to Procurement Automation
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/f5d926c5cd7731ef9432990c0ffb87975f268dac-1280x855.jpg"
                      alt="Seamless Integrations
"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Proactive Support</h3>
                    <p className="my-4 ">
                      Get ongoing support, system performance monitoring, and
                      proactive suggestions that enhance workflows for your
                      evolving business needs.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/citizen-development-support/"
                    >
                      Find Your AI Gaps
                      <span className="d-none">
                        This is a redirection to Procurement Automation
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/3d6825cfc4e44c99c5c606945e23f171b75ef715-1279x853.jpg"
                      alt="Seamless Integrations
"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Knowledge Transfer</h3>
                    <p className="my-4 ">
                      Empower your teams with our knowledge and best practices,
                      enabling your organization to maintain and expand
                      automation solutions.​
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/citizen-development-support/"
                    >
                      Find Your AI Gaps
                      <span className="d-none">
                        This is a redirection to Procurement Automation
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/8d8c744b42542b7e4089e04a492812a2c182c78f-1280x853.jpg"
                      alt="Knowledge Transfer"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default WorkAto
